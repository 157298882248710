import "./User.css";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, putUser } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useRef, useContext } from "react";
import Subs from "../../components/Subs/Subs";

export default function User(){
    const [taiderUser, ] = useContext(UserContext);
    const [ user, setUser ] = useState(null);
    const tos = useRef(null);
    const admin = useRef(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadUser(id)
    }, []);
    
    const loadUser = (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getUser(taiderUser.userData.token, id)
            .then(user => user?setUser(mapUser(user)):null)
            .catch(redirectToUsers);
    };

    const mapUser = (user) => {
        tos.current = user.tos_accepted;
        admin.current = user.admin;
        return (<div id="user-info">
            <img id="user-avatar" src={user.picture} alt="avatar"/>
            <p id="user-name">{user.name}</p>
            <p id="user-email">{user.email}</p>
            <label className="checkbox-container">ToS accepted
                <input type="checkbox" id="user-tos" name="user-tos" defaultChecked={user.tos_accepted} onChange={(event)=>handleTosChange(event)}/>
                <span className="checkmark"></span>
            </label>
            <label className="checkbox-container">Admin
                <input type="checkbox" id="user-admin" name="user-admin" defaultChecked={user.admin} onChange={(event)=>handleAdminChange(event)}/>
                <span className="checkmark"></span>
            </label>
            <button id="pois-button" onClick={()=>navigate(`/pois/${user.id}`)}>User's POIs</button>
        </div>);
    }

    const handleTosChange = (event) => {
        console.log("tos changed");
        console.log(event);
        tos.current = event.target.checked;
        if(taiderUser.userData == null){
            console.error("Please relog");
            return;
        }
        editUserInfo();
    }

    const handleAdminChange = (event) => {
        console.log("admin changed");
        console.log(event);
        admin.current = event.target.checked;
        if(taiderUser.userData == null){
            console.error("Please relog");
            return;
        }
        editUserInfo();
    }

    const editUserInfo = () => {
        putUser(taiderUser.userData.token, id, tos.current, admin.current)
            .then(test=>test?console.log(test):null);
    }

    const redirectToUsers = () => {
        alert("User with such id does not exist");
        navigate("/users");
    }

    const userPage = (<div id="user-data">
        {user}
        <Subs id={id} token={taiderUser.userData?taiderUser.userData.token:null}/>
    </div>)

    return (<div id="user">{user?userPage:<></>}</div>);
}