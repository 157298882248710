import Confirm from "../../assets/ic_confirm.png";
import "./Map.css";

export default function Map(props) {

    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var days = String(date.getDate()).padStart(2, '0');
        var months = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var years = String(date.getFullYear());
        var hours = String(date.getHours()).padStart(2, '0');
        var minutes = String(date.getMinutes()).padStart(2, '0');
        var seconds = String(date.getSeconds()).padStart(2, '0');
        var result = years + '/' + months + '/' + days + ' ' + hours + ':' + minutes + ":" + seconds;
        return result;
    }

    const row = (<>
        <span className={"mapfield mapfield-name"}>{formatDate(props.map.timestamp)}</span>
        <span className={"mapfield mapfield-actions"}>
            {!props.map.active?<button onClick={()=>{props.activate(props.token, props.map.id).then((response)=>response?props.callback(props.token):null)}}>Activate</button>:<img src={Confirm} alt="active map"/>}
        </span>
    </>);

    return(
        <div id={props.map.id} className={props.map.active?"map map-selected":"map"}>
            {row}
        </div>
    );
}