import { useState, useEffect, useContext, useRef } from "react";
import { getLayers, putLayers } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import LayerRow from "../../components/Layer/LayerRow.js";
import Layer from "../../components/Layer/Layer.js";
import "./Layers.css"

export default function Layers(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const [activeLayer, setActiveLayer] = useState("");
    const [json, setJson] = useState("");
    const [showBackup, setShowBackup] = useState(false);
    const uploadButton = useRef();
    
    useEffect(() => {
        loadLayers()
    }, []);

    useEffect(() => {
        setRows(mapLayers(json?json:null));
    }, [json]);

    
    const loadLayers = () => {
        if(taiderUser.userData == null){
            return;
        }
        getLayers(taiderUser.userData.token)
            .then(layers => layers?setJson(layers):null)
            .catch(handleError);
    };
    
    const mapLayers = (json) => {
        if(json==null){
            return null
        }
        json.sort(function(a, b){
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
          });
        return json.map(layer=>{return <LayerRow key={layer.id} layer={layer} edit={setActiveLayer} />});
    };

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
        uploadButton.current.value = null;
    }

    const onUploaded = (uploadResult) => {
        alert(uploadResult);
        uploadButton.current.value = null;
    }

    function onReaderLoad(event){
        console.log("uploading config");
        putLayers(taiderUser.userData.token, event.target.result).then(uploadResult => uploadResult?onUploaded(uploadResult):null).catch(handleError);
    }

    const handleFile = (target) => {
        console.log("file selection changed");
        if(target.files.length < 1){
            return
        }
        const inputFile = target.files[0];
        var reader = new FileReader();
        reader.onload = onReaderLoad;
        reader.readAsText(inputFile);
    }

    const toggleBackup = () => {
        setShowBackup(!showBackup);
    }

    function downloadBackup() {
        const a = document.createElement("a");
        const file = new Blob([JSON.stringify(json)], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = "layersConfig.json";
        a.click();
      }
    
    const header = (<div className="header-row">
        <span className="layersheader layersheader-name">Name</span>
        <span className="layersheader layersheader-parent">Parent</span>
        <span className="layersheader layersheader-actions">Actions</span>
    </div>);

    const backup = (<div id="backup-buttons" className={showBackup?"backup-show":""}>
        <button id="backup-show" onClick={()=>{toggleBackup()}}>Backup</button>
        <button id="backup-download" onClick={()=>{downloadBackup()}} >Download</button>
        <input id="backup-file" ref={uploadButton} name="backup" type="file" accept=".json" onChange={(e)=>{handleFile(e.target)}}/>
    </div>);
    
    return (<div id="layers">    
        {header}
        {rows}
        <Layer layer={activeLayer} callback={loadLayers} cancel={setActiveLayer} token={taiderUser.userData.token}/>
        {backup}
    </div>);
}