export class TaiderUser{
    constructor(user=null){
        this.user_key = "user_object_key";
        this.userData = user?user:JSON.parse(localStorage.getItem(this.user_key));
    }

    saveUser(user){
        localStorage.setItem(this.user_key, JSON.stringify(user));
        return new TaiderUser(user);
    }

    signOut(){
        localStorage.removeItem(this.user_key);
        return new TaiderUser();
    }

    isLoggedin(){
        const logged = (this.userData == null)?false:true;
        return logged;
    }
}