import Delete from "../../assets/ic_delete.png";
import Confirm from "../../assets/ic_confirm.png";
import Cancel from "../../assets/ic_cancel.png";
import { useState, useEffect } from "react";
import { getCodeEnabledSubs } from "../TaiderAPI/TaiderAPI";
import { useNavigate } from "react-router-dom";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import "./Code.css";

const states = {
    READONLY : 0,
    EDIT : 1, //not used here
    NEW : 2,
}

export default function Code(props) {
    const [state, setState] = useState(states.READONLY);
    const [subs, setSubs] = useState(null);
    const navigate = useNavigate();
    useEffect(()=>{
        if(props.new){
            getSubs()
            setState(states.NEW)
        }
    },[]);

    const getSubs = () => {
        if(props.token == null){
            return;
        }
        getCodeEnabledSubs(props.token)
            .then(result => result?setSubs(mapSubs(result)):null)
            .catch(handleError);
    }

    const mapSubs = (result) => {
        console.log(result);
        const mappedSubs = result.map(sub=><option value={sub.id} key={sub.id}>{sub.name}</option>);
        return mappedSubs;
    }

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }
    
    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const readonlyRow = (<>
        <span className={"codefield codefield-name codefield-big"} title={props.code.subName}>{props.code.subName}</span>
        <span className={"codefield codefield-count codefield-small"}>{props.code.count}</span>
        <span className={"codefield codefield-active codefield-small " + (props.code.active?"codefield-positive":"codefield-negative")}>{props.code.active?"YES":"NO"}</span>
        <span className={"codefield codefield-date codefield-big"}>{formatDate(props.code.timestamp)}</span>
        <span className={"codefield codefield-actions"}>
            <button onClick={()=>navigate("/code/"+props.code.id)}>
                Details
            </button>
            <button onClick={()=>deleteRow()}>
                <img src={Delete} alt="Delete"/>
            </button>
        </span>
    </>);

    const editableRow = (<>
        <span className="codefield codefield-editable codefield-name codefield-big">
            <select>
                {subs}
            </select>
        </span>
        <span className="codefield codefield-editable codefield-count codefield-small">
            <input type="text" defaultValue="0"/>
        </span>
        <span className="codefield codefield-editable codefield-active codefield-small">
            <label className="checkbox-container">
                <input type="checkbox" defaultChecked={false}/>
                <span className="checkmark"></span>
            </label>
        </span>
        <span className="codefield codefield-date codefield-big">{formatDate(Date.now()/1000)}</span>
        <span className="codefield codefield-actions">
            <button onClick={()=>saveRow()}>
                <img src={Confirm} alt="Confirm"/>
            </button>
            <button onClick={()=>cancelRow()}>
                <img src={Cancel} alt="Cancel"/>
            </button>
        </span>
    </>);

    const cancelRow = () => {
        if(state === states.NEW){
            props.delete(props.id);
        }else{
            setState(states.READONLY);
        }
    }

    const deleteRow = () => {
        showConfirmationDialog(()=>{
            props.delete(props.token, props.id).then((response)=>response?props.callback(props.token):null);
        })
    }

    const saveRow = () => {
        const row = document.getElementById(props.id);
        const subElement = row.querySelector(".codefield-editable.codefield-name select");
        const countElement = row.querySelector(".codefield-editable.codefield-count input");
        const activeElement = row.querySelector(".codefield-editable.codefield-active input");
        const subValue = subElement.value;
        const countValue = countElement.value;
        if(isNaN(countValue)){
            countElement.style.backgroundColor = "red";
            alert("Count must be a number!");
            return;
        }
        const activeValue = activeElement.checked;
        const code = new URLSearchParams({
            "subId": subValue,
            "count": countValue,
            "active": activeValue,
        });
        if(state === states.NEW){
            setState(states.READONLY);
            props.save(props.token, code).then((response)=>response?props.callback(props.token):null);
        }
    }

    const row = state===states.READONLY?readonlyRow:editableRow;

    return(
        <div id={props.id} className="code">
            {row}
        </div>
    );
}