import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult, OAuthProvider, signOut} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCl-uqOjmx9Htff_xjgNWOeAjkoULgdS68",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: "https://taider.firebaseio.com",
  projectId: "taider",
  storageBucket: "taider.appspot.com",
  messagingSenderId: "692216666529",
  appId: "1:692216666529:web:44391ceb86f886361613e0",
  measurementId: "G-Y3JSDG06VJ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export const googleSignInWithRedirect = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
  } catch (error) {
    console.error(error);
  }
}

export const appleSignInWithRedirect = async () => {
  try {
    await signInWithRedirect(auth, appleProvider);
  } catch (error) {
    console.error(error);
  }
}

export const firebaseSignOut = async () => {
  signOut(auth).then(() => {
    console.log("signout")
  }).catch((error) => {
    console.error(error);
  });
}

export const redirectResult = async () => {
  try {
    const result = await getRedirectResult(auth);
    if (result) {
      const user = result.user;
      const token = user.accessToken;
      return token;
    }else{
      console.log("no firebase result yet");
    }
  } catch (error) {
    alert("Error! Check console for details");
    console.error(error);
  }
}
