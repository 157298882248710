import Login from "../../components/Login/Login.js";
import Logo from "../../assets/logo.png";
import "./Main.css";
const { REACT_APP_ENV } = process.env;

export default function Main() {
    return (<div id="main">
        <img id="logo" src={Logo} alt="logo"/>
        <div id="env-name">{REACT_APP_ENV}</div>
        <Login/>
    </div>);
};