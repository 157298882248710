import { useNavigate } from "react-router-dom";
import User from "../../assets/ic_user.png";
import Delete from "../../assets/ic_delete.png";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import "./Purchase.css";
import { useState, useEffect } from "react";


export default function Purchase(props) {
    const [formattedTime, setFormattedTime] = useState(null);
    const navigate = useNavigate();
    useEffect(()=>{
        setFormattedTime(formatTime(props.purchase.timestamp));
    },[]);

    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }
    
    const formatTime = (timestamp) => {
        if(props.duration==14){
            console.log(props.purchase);
        }
        var duration = props.duration * 86400 // days to seconds: 24*60*60
        var now = parseInt(Date.now()/1000);
        var end = parseInt(timestamp) + duration
        var seconds = end - now;
        if(seconds <= 0){
            return null;
        }
        var hours = parseInt(seconds / 3600);
        seconds = parseInt(seconds % 3600);
        var minutes = parseInt(seconds / 60);
        seconds = parseInt(seconds % 60);
        let hoursStr = hours.toString().padStart(2, '0');
        let minutesStr = minutes.toString().padStart(2, '0');
        let secondsStr = seconds.toString().padStart(2, '0');
        return hoursStr + ":" + minutesStr + ":" + secondsStr
    }

    const row = (<>
        <span className={"purchasefield purchasefield-subname"}>{props.purchase.subName}</span>
        <span className={"purchasefield purchasefield-username"}>{props.purchase.userName}</span>
        <span className={"purchasefield purchasefield-date"}>{formatDate(props.purchase.timestamp)}</span>
        <span className={"purchasefield purchasefield-time " + (formattedTime===null?"purchasefield-negative":"")}>{formattedTime===null?"EXPIRED":formattedTime}</span>
        <span className={"purchasefield purchasefield-actions"}>
            <button onClick={()=>navigate(`/user/${props.purchase.user_id}`)}>
                <img src={User} alt="User details"/>
            </button>
            <button onClick={()=>{deletePurchase()}}>
                <img src={Delete} alt="Delete purchase"/>
            </button>
        </span>
    </>);

    const deletePurchase = () => {
        showConfirmationDialog(()=>{
            props.delete(props.token, props.purchase.id).then(result=>result?props.callback():null);
        })
    }

    return(
        <div id={props.id} className="purchase">
            {row}
        </div>
    );
}