export const showConfirmationDialog = (callback) => {
    let dialog = document.querySelector("#confirmation-dialog");
    dialog.style.display = "flex";
    dialog.querySelector("#confirmation-dialog-positive").onclick=(()=>closeConfirmationDialog(dialog, callback));
    dialog.querySelector("#confirmation-dialog-negative").onclick=(()=>closeConfirmationDialog(dialog, null));
}

const closeConfirmationDialog = (dialog, callback) => {
    dialog.querySelector("#confirmation-dialog-positive").removeAttribute("onclick");
    dialog.querySelector("#confirmation-dialog-negative").removeAttribute("onclick");
    document.querySelector("#confirmation-dialog").style.display="none";
    if(callback != null){
        callback();
    }
}