import { useState, useEffect, useContext } from "react";
import { getCodes, deleteCodeGroup, postCode } from "../../components/TaiderAPI/TaiderAPI";
import { UserContext } from "../../views/App/App.js";
import Add from "../../assets/ic_add.png";
import Code from "../../components/Code/Code";
import "./Codes.css";

export default function Codes() {
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");    

    useEffect(() => {
        loadCodes()
    }, []);

    const loadCodes = () => {
        if(taiderUser.userData == null){
            return;
        }
        setRows("Hello codes");
        getCodes(taiderUser.userData.token)
            .then(codes => codes?setRows(mapCodes(codes)):null)
            .catch(handleError);
    };

    const mapCodes = (codes) => {
        console.log(codes[0]);
        var mappedCodes = codes.map(code => <Code key={code.id} id={code.id} new={false} code={code} token={taiderUser.userData.token} save={postCode} delete={deleteCodeGroup} callback={loadCodes} />)
        return mappedCodes;
    };

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const removeCode = (id) => {
        console.log("removing " + id);
        setRows(
            rows.filter(code=>code.id !== id)
        );
    }

    const addCode = () => {
        const timestamp = Date.now();
        setRows(rows.concat([
            <Code
                id={timestamp}
                key={timestamp}
                code={{
                    id: timestamp,
                    subName:"",
                    count:"",
                    active:false,
                }}
                new={true}
                token={taiderUser.userData.token}
                save={postCode}
                delete={removeCode}
                callback={loadCodes}
            />
        ]))
    }

    const header = (<div className="header-row">
        <span className="codesheader codesheader-name codesheader-big">Sub name</span>
        <span className="codesheader codesheader-count codesheader-small">Count</span>
        <span className="codesheader codesheader-active codesheader-small">Active</span>
        <span className="codesheader codesheader-date codesheader-big">Creation date</span>
        <span className="codesheader codesheader-actions"></span>
    </div>);

    return (<div id="codes">
        {header}
        {rows}
        <div id="sub-add">
            <button onClick={()=>addCode()}>
                <img src={Add} alt="add new sub"/>
            </button>
        </div>
    </div>);
}