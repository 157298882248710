import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './views/App/App';
import Main from "./views/Main/Main";
import Subs from './views/Subs/Subs';
import Users from './views/Users/Users';
import Purchases from './views/Purchases/Purchases';
import Codes from './views/Codes/Codes';
import Maps from './views/Maps/Maps';
import User from "./views/User/User";
import Code from "./views/Code/Code";
import POIs from './views/POIs/POIs';
import Layers from './views/Layers/Layers';
import NoPage from "./views/NoPage/NoPage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Main />} />
          <Route path="subs" element={<Subs />} />
          <Route path="users" element={<Users />} />
          <Route path="purchases" element={<Purchases />} />
          <Route path="codes" element={<Codes />} />
          <Route path="maps" element={<Maps />} />
          <Route path="pois" element={<POIs />} />
          <Route path="layers" element={<Layers />} />
          <Route path="pois/:id" element={<POIs />} />
          <Route path="user/:id" element={<User />} />
          <Route path="code/:id" element={<Code />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
);
