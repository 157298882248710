import "./Code.css";
import { useParams, useNavigate } from "react-router-dom";
import { getCode, deleteCode, putCode } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext, useRef } from "react";
import Delete from "../../assets/ic_delete.png";

export default function Code(){
    const [ taiderUser, ] = useContext(UserContext);
    const [ code, setCode ] = useState(null);
    const [ codes, setCodes ] = useState(null);
    const { id } = useParams();
    const active = useRef(null)
    const navigate = useNavigate();

    useEffect(() => {
        loadCode(id)
    }, []);
    
    const loadCode = (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getCode(taiderUser.userData.token, id)
            .then(result => result?mapCode(result):null)
            .catch(redirectToCodes);
    };

    const formatDate = (timestamp) => {
        if(timestamp == null){
            return null;
        }
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const mapCode = (code) => {
        active.current = code.active;
        let mappedCode = (<div id="code-info">
            <div className="code-info-row">
                <span className="code-info-field">Sub name</span>
                <span className="code-info-field">{code.subName}</span>
            </div>
            <div className="code-info-row">
                <span className="code-info-field">Creation date</span>
                <span className="code-info-field">{formatDate(code.timestamp)}</span>
            </div>
            <div className="code-info-row">
                <span className="code-info-field">Consumed</span>
                <span className="code-info-field">Activated</span>
                <span className="code-info-field">Total</span>
            </div>
            <div className="code-info-row">
                <span className="code-info-field">{code.consumedCount}</span>
                <span className="code-info-field">{code.activatedCount}</span>
                <span className="code-info-field">{code.totalCount}</span>
            </div>
            <label className="checkbox-container">Active
                <input type="checkbox" id="code-active" name="code-active" defaultChecked={code.active} onChange={(event)=>handleActiveChange(event)}/>
                <span className="checkmark"></span>
            </label>
        </div>);
        setCode(mappedCode);
        mapCodes(code.codes);
        return true;
    }

    const redirectToCodes = () => {
        alert("Code with such id does not exist");
        navigate("/codes");
    }

    const handleActiveChange = (event) => {
        console.log("active changed");
        console.log(event);
        console.log(active);
        active.current = event.target.checked;
        if(taiderUser.userData == null){
            console.error("Please relog");
            return;
        }
        putCode(taiderUser.userData.token, id, active.current)
            .then(test=>test?console.log(test):null);
    }

    const header = (<div className="header-row">
        <span className="code-codes-header code-codes-header-code">Code</span>
        <span className="code-codes-header code-codes-header-consumed">Consumed</span>
        <span className="code-codes-header code-codes-header-activated">Activated</span>
        <span className="code-codes-header code-codes-header-actions"></span>
    </div>);

    const mapCodes = (codes) => {
        setCodes(codes.map(code=>{return (<div className="code-row" key={code.id}>
            <span className="code-codes-field">{code.code}</span>
            <span className={"code-codes-field " + (code.consumed?"":"code-codes-field-negative")}>{code.consumed?formatDate(code.consumed):"NO"}</span>
            <span className={"code-codes-field " + (code.activated?"":"code-codes-field-negative")}>{code.activated?formatDate(code.activated):"NO"}</span>
            <span className="code-codes-field code-codes-field-actions">
                <button onClick={()=>{deleteCode(taiderUser.userData.token, code.id).then(loadCode(id))}}>
                    <img src={Delete} alt="delete code"/>
                </button>
            </span>
        </div>)}));
    };

    const codePage = (<div id="code-data">
        {code}
        <h2>Codes</h2>
        {header}
        {codes}
    </div>)

    return (<div id="code">{code?codePage:<></>}</div>);
}