import User from "../../components/User/User.js";
import { useState, useEffect, useContext } from "react";
import { getUsers } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import "./Users.css";

export default function Users(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");

    useEffect(() => {
        loadUsers()
    }, []);
    
    const loadUsers = () => {
        if(taiderUser.userData == null){
            return;
        }
        getUsers(taiderUser.userData.token)
            .then(users => users?setRows(mapUsers(users)):null)
            .catch(handleError);
    };
    
    const mapUsers = (users) => {
        return users.map(user=>{return <User key={user.id} user={user} />});
    };

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="usersheader usersheader-avatar"></span>
        <span className="usersheader usersheader-name">Name</span>
        <span className="usersheader usersheader-email usersheader-big">email</span>
        <span className="usersheader usersheader-tos usersheader-small">TOS</span>
        <span className="usersheader usersheader-admin usersheader-small">ADMIN</span>
        <span className="usersheader usersheader-actions">Actions</span>
    </div>);
    
    return (<div id="users">    
        {header}
        {rows}
    </div>);
}