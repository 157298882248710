import { useNavigate } from "react-router-dom";
import User from "../../assets/ic_user.png";
import Alga from "../../assets/poi/ic_poi_alga.png";
import Anchor from "../../assets/poi/ic_poi_anchor.png";
import Boat from "../../assets/poi/ic_poi_boat.png";
import Checker from "../../assets/poi/ic_poi_checker.png";
import Cross from "../../assets/poi/ic_poi_cross.png";
import Fish from "../../assets/poi/ic_poi_fish.png";
import Heart from "../../assets/poi/ic_poi_heart.png";
import House from "../../assets/poi/ic_poi_house.png";
import Leaf from "../../assets/poi/ic_poi_leaf.png";
import Pin from "../../assets/poi/ic_poi_pin.png";
import Star from "../../assets/poi/ic_poi_star.png";
import Wreck from "../../assets/poi/ic_poi_wreck.png";
import Else from "../../assets/poi/ic_poi_else.png";
import "./POI.css";

export default function POI(props) {
    const navigate = useNavigate();

    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const displayIcon = (iconName) => {
        var iconSrc = null;
        console.log("iconName " + iconName);
        console.log(props.poi);
        switch (iconName) {
            case "ALGA":
                iconSrc = Alga;
                break;
            case "ANCHOR":
                iconSrc = Anchor;
                break;
            case "BOAT":
                iconSrc = Boat;
                break;
            case "CHECKER":
                iconSrc = Checker;
                break;
            case "CROSS":
                iconSrc = Cross;
                break;
            case "FISH":
                iconSrc = Fish;
                break;
            case "HEART":
                iconSrc = Heart;
                break;
            case "HOUSE":
                iconSrc = House;
                break;
            case "LEAF":
                iconSrc = Leaf;
                break;
            case "PIN":
                iconSrc = Pin;
                break;                
            case "STAR":
                iconSrc = Star;
                break;
            case "WRECK":
                iconSrc = Wreck;
                break;
            default:
                iconSrc = Else;
                break;
        }
        return (<img src={iconSrc} alt={iconName}/>);
    }

    const row = (<>
        <span className={"poifield poifield-subname"}>{props.poi.name}</span>
        <span className={"poifield poifield-date"}>{formatDate(props.poi.timestamp)}</span>
        <span className={"poifield poifield-icon poifield-small"}>{displayIcon(props.poi.icon_name)}</span>
        <span className={"poifield poifield-latitude"}>{props.poi.latitude}</span>
        <span className={"poifield poifield-longitude"}>{props.poi.longitude}</span>
        <span className={"poifield poifield-deleted poifield-small " + (props.poi.deleted?"poifield-negative":"poifield-positive")}>{props.poi.deleted?"YES":"NO"}</span>
        <span className={"poifield poifield-actions"}>
            <button onClick={()=>navigate(`/user/${props.poi.user_id}`)}>
                <img src={User} alt="User details"/>
            </button>
            <button onClick={()=>navigate(`/pois/${props.poi.user_id}`)}>
                <img src={User} alt="Users POIs"/>
            </button>
        </span>
    </>);

    return(
        <div id={props.id} className="poi">
            {row}
        </div>
    );
}