import { redirectResult, firebaseSignOut } from "../../components/Firebase/Firebase.js";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import NavBar from "../../components/NavBar/Navbar";
import { useState, useEffect, createContext } from "react";
import { TaiderUser } from "../../components/TaiderUser/TaiderUser";
import { signIn } from "../../components/TaiderAPI/TaiderAPI.js";
import "./App.css";
import "../../components/LoadingModal/LoadingModal.css";
import "../../components/ConfirmationDialog/ConfirmationDialog.css";

export const UserContext = createContext();
export const SignOutContext = createContext();

export default function App() {
  const [taiderUser, setTaiderUser] = useState(new TaiderUser());
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if(!taiderUser.isLoggedin() && location.pathname !== "/"){
      navigate("/");
    }
    redirectResult()
        .then(token => token?signIn(token):null)
        .then(result => result?setTaiderUser(taiderUser.saveUser(result)):null)
        .catch(handleError);
  }, []);
  const handleError = () => {
      alert("error signing in, check console")
      signOut();
  }
  const signOut = () => {
      firebaseSignOut();
      setTaiderUser(taiderUser.signOut());
      navigate("/");
  };
  const navbar = <>{taiderUser.isLoggedin()?<NavBar/>:<></>}</>;

  return (
    <UserContext.Provider value={[taiderUser, setTaiderUser]}>
      <SignOutContext.Provider value={signOut}>
        {navbar}
        <Outlet />
        <div id="loading-modal" data-counter="0">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div id="confirmation-dialog">
          <div id="confirmation-dialog-window">
            <p id="confirmation-dialog-message">
              Na pewno usunąć?
            </p>
            <div id="confirmation-dialog-buttons">
              <button id="confirmation-dialog-positive">Yes</button>
              <button id="confirmation-dialog-negative">No</button>
            </div>
          </div>
        </div>
      </SignOutContext.Provider>
    </UserContext.Provider>
  )
};