import "./Layer.css";

export default function LayerRow(props) {
    const row = (<>
        <span className={"layerfield layerfield-name"}>{props.layer.name}</span>
        <span className={"layerfield layerfield-parent"}>{props.layer.parent}</span>
        <span className={"layerfield layerfield-actions"}>
            <button onClick={()=>props.edit(props.layer)}>EDIT</button>
        </span>
    </>);

    return(
        <div id={props.layer.id} className="layer">
            {row}
        </div>
    );
}