import { googleSignInWithRedirect, appleSignInWithRedirect} from "../Firebase/Firebase.js";
import { useContext } from "react";
import { UserContext, SignOutContext } from "../../views/App/App.js";
import { GoogleLoginButton, AppleLoginButton } from "react-social-login-buttons";

import "./Login.css";

export default function Login() {
    const [taiderUser, ] = useContext(UserContext);
    const signOut = useContext(SignOutContext);
    const loginGoogle = googleSignInWithRedirect;
    const loginApple = appleSignInWithRedirect;
    
    const signInButtons = (
        <>
            <GoogleLoginButton onClick={loginGoogle}/>
            <AppleLoginButton onClick={loginApple}/>
        </>
    );
    const signOutButtons = (
        <>
            <button id="signout" onClick={signOut}>SignOut</button>
        </>
    );
    const buttons = <div id="buttons">{taiderUser.userData!=null?signOutButtons:signInButtons}</div>;
    return (<>
        {buttons}
    </>);
};