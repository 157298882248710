import { putLayer } from "../../components/TaiderAPI/TaiderAPI.js";
import { useState, useEffect, useRef } from "react";
import "./Layer.css";

export default function Layer(props) {
    const background = useRef();
    const scrollMe = useRef();

    const [parent, setParent] = useState();
    const [maxVis, setMaxVis] = useState();
    const [color, setColor] = useState();
    const [fill, setFill] = useState();
    const [lineWidth, setLineWidth] = useState();
    const [sizeX, setSizeX] = useState();
    const [sizeY, setSizeY] = useState();
    const [priority, setPriority] = useState();
    const [importance, setImportance] = useState();
    const [selectable, setSelectable] = useState();
    const [maplyType, setMaplyType] = useState();
    const [dbfFieldName, setDbfFieldName] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [outlineColor, setOutlineColor] = useState();
    const [outlineSize, setOutlineSize] = useState();
    const [offsetX, setOffsetX] = useState();
    const [offsetY, setOffsetY] = useState();
    const [fontColor, setFontColor] = useState();
    const [fontSize, setFontSize] = useState();


    useEffect(()=>{
        if(props.layer){
            background.current.style.display = "flex";
            scrollMe.current.scrollTop = 0;
            setParent(props.layer.parent);
            setMaxVis(props.layer.max_visibility);
            setColor(props.layer.color);
            setFill(props.layer.fill);
            setLineWidth(props.layer.line_width);
            setSizeX((Array.isArray(props.layer.size)&&props.layer.size.length>0)?props.layer.size[0]:null)
            setSizeY((Array.isArray(props.layer.size)&&props.layer.size.length>0)?props.layer.size[1]:null)
            setPriority(props.layer.priority);
            setImportance(props.layer.importance);
            setSelectable(props.layer.selectable);
            setMaplyType(props.layer.maply_type);
            setDbfFieldName(props.layer.dbf_field_name);
            setBackgroundColor(props.layer.background_color);
            setOutlineColor(props.layer.outline_color);
            setOutlineSize(props.layer.outline_size);
            setOffsetX((Array.isArray(props.layer.offset)&&props.layer.offset.length>0)?props.layer.offset[0]:null)
            setOffsetY((Array.isArray(props.layer.offset)&&props.layer.offset.length>0)?props.layer.offset[1]:null)
            setFontColor(props.layer.font_color);
            setFontSize(props.layer.font_size);
        }else{
            background.current.style.display = "none";
        }
    },[props.layer]);
    
    const saveLayer = () => {
        const layer = new URLSearchParams({
            "id": props.layer.id,
            "name": props.layer.name,
        });
        if(parent != null){
            layer.append("parent", parent);
        }
        if(maxVis != null){
            layer.append("max_visibility", maxVis);
        }
        if(color != null){
            layer.append("color", color);
        }
        if(fill != null){
            layer.append("fill", fill);
        }
        if(lineWidth != null){
            layer.append("line_width", lineWidth);
        }
        if(priority != null){
            layer.append("priority", priority);
        }
        if(importance != null){
            layer.append("importance", importance);
        }
        if(selectable != null){
            layer.append("selectable", selectable);
        }
        if(maplyType != null){
            layer.append("maply_type", maplyType);
        }
        if(dbfFieldName != null){
            layer.append("dbf_field_name", dbfFieldName);
        }
        if(backgroundColor != null){
            layer.append("background_color", backgroundColor);
        }
        if(outlineColor != null){
            layer.append("outline_color", outlineColor);
        }
        if(outlineSize != null){
            layer.append("outline_size", outlineSize);
        }
        if(fontColor != null){
            layer.append("font_color", fontColor);
        }
        if(fontSize != null){
            layer.append("font_size", fontSize);
        }
        if(sizeX != null && sizeY != null){
            layer.append("size", Number.parseFloat(sizeX));
            layer.append("size", Number.parseFloat(sizeY));
        }
        if(offsetX != null && offsetY != null){
            layer.append("offset", Number.parseFloat(offsetX));
            layer.append("offset", Number.parseFloat(offsetY));
        }
        putLayer(props.token, layer).then((response)=>response?cancelLayer():null);
    }

    const cancelLayer = () => {
        props.cancel(null);
        props.callback();
    }
    
    const header = (<>
        <div id="layerheader">
            <span>{props.layer?props.layer.name:""}</span>
        </div>
        <p id="layersubheader">
            <span>Name</span>
            <span>Value</span>
            <span>Null?</span>
        </p>
    </>);

    const inputs = (<div id="layerinputs" ref={scrollMe}>
        <p className={"layereditfield"}>
            <label htmlFor="parent">
                Parent
            </label>
            <input name="parent" type="text" value={(parent!=null)?parent:""} 
                onChange={(e) => setParent(e.target.value)}>
            </input>
            <input type="checkbox" checked={parent==null} onChange={()=>setParent(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="maxvis" title="This layer will show up when in-app scale at max lenght will show this number of meters">
                Max Visibility
            </label>
            <input name="maxvis" type="number" value={(maxVis!=null)?maxVis:""}
                onChange={(e) => setMaxVis(e.target.value)}>
            </input>
            <input type="checkbox" checked={maxVis==null} onChange={()=>setMaxVis(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="color" title="Color for lines and polygons">
                Color
            </label>
            <input name="color" type="color" value={(color!=null)?color:"#000000"}
                onChange={(e) => setColor(e.target.value)}>
            </input>
            <input type="checkbox" checked={color==null} onChange={()=>setColor(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="fill" title="Should polygons be filled?">
                Fill
            </label>
            <input name="fill" type="checkbox" checked={(fill!=null)?fill:false}
                onChange={(e) => setFill(e.target.checked)}>
            </input>
            <input type="checkbox" checked={fill==null} onChange={()=>setFill(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="linewidth" title="Width of lines">
                Line width
            </label>
            <input name="linewidth" type="number" value={(lineWidth!=null)?lineWidth:""}
                onChange={(e) => setLineWidth(e.target.value)}>
            </input>
            <input type="checkbox" checked={lineWidth==null} onChange={()=>setLineWidth(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="sizex" title="Height of marker">
                Height
            </label>
            <input name="sizex" type="number" value={(sizeX!=null)?sizeX:""}
                onChange={(e) => setSizeX(e.target.value)}>
            </input>
            <input type="checkbox" checked={(sizeX==null&&sizeY==null)} onChange={()=>{setSizeX(null);setSizeY(null)}}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="sizey" title="Width of marker">
                Width
            </label>
            <input name="sizey" type="number" value={(sizeY!=null)?sizeY:""}
                onChange={(e) => setSizeY(e.target.value)}>
            </input>
            <span className="placeholder"></span>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="priority" title="Layers with lower priority will disapear when in collision with layers with higher priority">
                Draw priority
            </label>
            <input name="priority" type="number" value={(priority!=null)?priority:""}
                onChange={(e) => setPriority(e.target.value)}>
            </input>
            <input type="checkbox" checked={priority==null} onChange={()=>setPriority(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="importance" title="Layer with higher importance will be drawn above layers with lower importance">
                Draw importance
            </label>
            <input name="importance" type="number" value={(importance!=null)?importance:""}
                onChange={(e) => setImportance(e.target.value)}>
            </input>
            <input type="checkbox" checked={importance==null} onChange={()=>setImportance(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="selectable" title="Will trigger stuff when clicked">
                Selectable
            </label>
            <input name="selectable" type="checkbox" checked={(selectable!=null)?selectable:false}
                onChange={(e) => setSelectable(e.target.checked)}>
            </input>
            <input type="checkbox" checked={selectable==null} onChange={()=>setSelectable(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="maplytype" title="Vector, marker or label">
                Maply type
            </label>
            <input name="maplytype" type="text" value={(maplyType!=null)?maplyType:""}
                onChange={(e) => setMaplyType(e.target.value)}>
            </input>
            <input type="checkbox" checked={maplyType==null} onChange={()=>setMaplyType(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="dbffieldname" title="Name of column with stuff in dbf document">
                Dbf field name
            </label>
            <input name="dbffieldname" type="text" value={(dbfFieldName!=null)?dbfFieldName:""}
                onChange={(e) => setDbfFieldName(e.target.value)}>
            </input>
            <input type="checkbox" checked={dbfFieldName==null} onChange={()=>setDbfFieldName(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="backgroundcolor" title="Color of rectangles under labels">
                Background color
            </label>
            <input name="backgroundcolor" type="color" value={(backgroundColor!=null)?backgroundColor:"#000000"}
                onChange={(e) => setBackgroundColor(e.target.value)}>
            </input>
            <input type="checkbox" checked={backgroundColor==null} onChange={()=>setBackgroundColor(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="outlinecolor" title="Color of labels' text's outline">
                Outline color
            </label>
            <input name="outlinecolor" type="color" value={(outlineColor!=null)?outlineColor:"#000000"}
                onChange={(e) => setOutlineColor(e.target.value)}>
            </input>
            <input type="checkbox" checked={outlineColor==null} onChange={()=>setOutlineColor(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="outlinesize" title="Width of labels' text's outline">
                Outline size
            </label>
            <input name="outlinesize" type="number" value={(outlineSize!=null)?outlineSize:""}
                onChange={(e) => setOutlineSize(e.target.value)}>
            </input>
            <input type="checkbox" checked={outlineSize==null} onChange={()=>setOutlineSize(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="offsetx" title="Horizontal offset of label">
                Offset horizontal
            </label>
            <input name="offsetx" type="number" value={(offsetX!=null)?offsetX:""}
                onChange={(e) => setOffsetX(e.target.value)}>
            </input>
            <input type="checkbox" checked={(offsetX==null&&offsetY==null)} onChange={()=>{setOffsetX(null);setOffsetY(null)}}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="offsety" title="Vertival offset of label">
                Offset vertical
            </label>
            <input name="offsety" type="number" value={(offsetY!=null)?offsetY:""}
                onChange={(e) => setOffsetY(e.target.value)}>
            </input>
            <span className="placeholder"></span>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="fontcolor" title="Color of labels' text">
                Font color
            </label>
            <input name="fontcolor" type="color" value={(fontColor!=null)?fontColor:"#000000"}
                onChange={(e) => setFontColor(e.target.value)}>
            </input>
            <input type="checkbox" checked={fontColor==null} onChange={()=>setFontColor(null)}/>
        </p>
        <p className={"layereditfield"}>
            <label htmlFor="fontsize" title="Size of labels' text">
                Font size
            </label>
            <input name="fontsize" type="number" value={(fontSize!=null)?fontSize:""}
                onChange={(e) => setFontSize(e.target.value)}>
            </input>
            <input type="checkbox" checked={fontSize==null} onChange={()=>setFontSize(null)}/>
        </p>
    </div>);

    const buttons = (<div id="layerbuttons">
        <button onClick={()=>saveLayer()}>save</button>
        <button onClick={()=>cancelLayer()}>cancel</button>
    </div>);

    return(
        <div id="activelayer" ref={background}>
            <div id="activelayermodal">
                {header}
                {inputs}
                {buttons}
            </div>
        </div>
    );
}