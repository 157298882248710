import "./Sub.css";
import Edit from "../../assets/ic_edit.png";
import Delete from "../../assets/ic_delete.png";
import Confirm from "../../assets/ic_confirm.png";
import Cancel from "../../assets/ic_cancel.png";
import { useState, useEffect } from "react";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

const states = {
    READONLY : 0,
    EDIT : 1,
    NEW : 2,
}
export default function Sub(props) {
    const [state, setState] = useState(states.READONLY);
    useEffect(()=>{
        if(props.new){
            setState(states.NEW)
        }
    },[]);

    const readonlyRow = (<>
        <span className={"subfield subfield-name subfield-big"} title={props.sub.name}>{props.sub.name}</span>
        <span className={"subfield subfield-price"}>{props.sub.price} PLN</span>
        <span className={"subfield subfield-sku subfield-big"}>{props.sub.sku}</span>
        <span className={"subfield subfield-duration subfield-small"}>{props.sub.duration} Days</span>
        <span className={"subfield subfield-trial subfield-small " + (props.sub.trial?"subfield-positive":"subfield-negative")}>{props.sub.trial?"YES":"NO"}</span>
        <span className={"subfield subfield-codesenabled subfield-small " + (props.sub.codes_enabled?"subfield-positive":"subfield-negative")}>{props.sub.codes_enabled?"YES":"NO"}</span>
        <span className={"subfield subfield-visible subfield-small " + (props.sub.hidden?"subfield-negative":"subfield-positive")}>{props.sub.hidden?"NO":"YES"}</span>
        <span className={"subfield subfield-actions"}>
            <button onClick={()=>setState(states.EDIT)}>
                <img src={Edit} alt="Edit"/>
            </button>
            <button onClick={()=>deleteRow()}>
                <img src={Delete} alt="Delete"/>
            </button>
        </span>
    </>);

    const editableRow = (<>
        <span className="subfield subfield-editable subfield-name subfield-big"><input type="text" defaultValue={props.sub.name}/></span>
        <span className="subfield subfield-editable subfield-price"><input type="text" defaultValue={props.sub.price}/></span>
        <span className="subfield subfield-editable subfield-sku subfield-big"><input type="text" defaultValue={props.sub.sku}/></span>
        <span className="subfield subfield-editable subfield-duration subfield-small"><input type="text" defaultValue={props.sub.duration}/></span>
        <span className="subfield subfield-editable subfield-trial subfield-small">
            <label className="checkbox-container">
                <input type="checkbox" defaultChecked={props.sub.trial}/>
                <span className="checkmark"></span>
            </label>
        </span>
        <span className="subfield subfield-editable subfield-codesenabled subfield-small">
            <label className="checkbox-container">
                <input type="checkbox" defaultChecked={props.sub.codes_enabled}/>
                <span className="checkmark"></span>
            </label>
        </span>
        <span className="subfield subfield-editable subfield-visible subfield-small">    
            <label className="checkbox-container">
                <input type="checkbox" defaultChecked={!props.sub.hidden}/>
                <span className="checkmark"></span>
            </label>
        </span>
        <span className="subfield subfield-actions">
            <button onClick={()=>saveRow()}>
                <img src={Confirm} alt="Confirm"/>
            </button>
            <button onClick={()=>cancelRow()}>
                <img src={Cancel} alt="Cancel"/>
            </button>
        </span>
    </>);

    const deleteRow = () => {
        console.log("deleting sub");
        showConfirmationDialog(()=>{
            props.delete(props.token, props.sub.id).then((response)=>response?props.callback(props.token):null);
        });
    }

    const cancelRow = () => {
        if(state === states.NEW){
            props.delete(props.sub.id);
        }else{
            setState(states.READONLY);
        }
    }

    const saveRow = () => {
        const row = document.getElementById(props.sub.id)
        const nameElement = row.querySelector(".subfield-editable.subfield-name input");
        const priceElement = row.querySelector(".subfield-editable.subfield-price input");
        const skuElement = row.querySelector(".subfield-editable.subfield-sku input");
        const durationElement = row.querySelector(".subfield-editable.subfield-duration input");
        const trialElement = row.querySelector(".subfield-editable.subfield-trial input");
        const codesenabledElement = row.querySelector(".subfield-editable.subfield-codesenabled input");
        const visibleElement = row.querySelector(".subfield-editable.subfield-visible input");
        const nameValue = nameElement.value;
        if(nameValue == ""){
            nameElement.style.backgroundColor = "red";
            alert("Name can't be empty!");
            return;
        }
        const priceValue = priceElement.value;
        if(isNaN(priceValue)){
            priceElement.style.backgroundColor = "red";
            alert("Price must be a number!");
            return;
        }
        const skuValue = skuElement.value; // can be whatever
        const durationValue = durationElement.value;
        if(isNaN(durationValue)){
            durationElement.style.backgroundColor = "red";
            alert("duration must be a number!");
            return;
        }
        const trialValue = trialElement.checked;
        const codesenabledValue = codesenabledElement.checked;
        const hiddenValue = !visibleElement.checked;
        const sub = new URLSearchParams({
            "id": props.sub.id,
            "name": nameValue,
            "price": (priceValue?priceValue:0),
            "sku": skuValue,
            "duration": (durationValue?durationValue:0),
            "trial": trialValue,
            "codes_enabled": codesenabledValue,
            "hidden": hiddenValue,
        });
        if(state === states.NEW){
            setState(states.READONLY);
            props.save(props.token, sub).then((response)=>response?props.callback(props.token):null);
        }else{
            setState(states.READONLY);
            props.edit(props.token, sub).then((response)=>response?props.callback(props.token):null);
        }
    }

    const row = state===states.READONLY?readonlyRow:editableRow;

    return(
        <div id={props.sub.id} className="sub">
            {row}
        </div>
    );
}
