import { useState, useEffect } from "react";
import { getActiveSubs, deleteActiveSub, getSubs, postActiveSub } from "../TaiderAPI/TaiderAPI";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import Delete from "../../assets/ic_delete.png";
import Confirm from "../../assets/ic_confirm.png";
import Cancel from "../../assets/ic_cancel.png";
import "./Subs.css";

export default function Subs(props) {
    const [rows, setRows] = useState("");

    useEffect(() => {
        loadSubs()
    }, []);

    const loadSubs = () => {
        if(props.token == null){
            return;
        }
        getActiveSubs(props.token, props.id)
            .then(subs => subs?setRows(mapSubs(subs)):null)
            .catch(handleError);
    }

    const mapSubs = (subs) => {
        return subs.map(sub => {
            let time = sub.remainingTime;
            let formattedTime = formatTime(time);
            console.log(time);
            console.log(formattedTime);
            return (<div className="user-sub" key={sub.purchaseId}>
                <span className="user-sub-field user-sub-field-name">{sub.sub.name}</span>
                <span className="user-sub-field user-sub-field-time">{formattedTime}</span>
                <span className="user-sub-field user-sub-field-actions">
                    <button onClick={()=>deleteSub(sub.purchaseId)}>
                        <img src={Delete} alt="delete"/>
                    </button>
                </span>
            </div>);
        })
    }

    const deleteSub = (purchaseId) => {
        showConfirmationDialog(()=>{
            deleteActiveSub(props.token, purchaseId)
                .then(loadSubs)
                .catch(handleError);
        });
    }

    const addSub = async () => {
        try{
            const subs = await getSubs(props.token);
            const timestamp = Date.now();
            setRows(rows.concat([
                <div className="user-sub" key={timestamp} id={timestamp}>
                    <span className="user-sub-field user-sub-field-sub">
                        <select>
                            {subs.map(sub=><option value={sub.id} key={sub.id}>{sub.name}</option>)}
                        </select>
                    </span>
                    <span className="user-sub-field user-sub-field-actions">
                        <button onClick={()=>saveSub(timestamp)}>
                            <img src={Confirm} alt="confirm" />
                        </button>
                        <button onClick={()=>cancelSub(timestamp)}>
                            <img src={Cancel} alt="cancel" />
                        </button>
                    </span>
                </div>
            ]))
        }catch(error){
            alert("failed to fetch available subs, check console");
            console.error(error);
        }
    }

    const saveSub = (id) => {
        let subId = document.getElementById(id).querySelector("select").value;
        postActiveSub(props.token, props.id, subId)
            .then(response => response?loadSubs():null)
            .catch(handleError);
    }

    const cancelSub = (id) => {
        setRows(
            rows.filter(sub=>sub.id !== id)
        );
    }

    const handleError = (error) => {
        console.error(error);
    }
    
    const formatTime = (seconds) => {    
        var hours = parseInt(seconds / 3600);
        seconds = parseInt(seconds % 3600);
        var minutes = parseInt(seconds / 60);
        seconds = parseInt(seconds % 60);
        let hoursStr = hours.toString().padStart(2, '0');
        let minutesStr = minutes.toString().padStart(2, '0');
        let secondsStr = seconds.toString().padStart(2, '0');
        return hoursStr + ":" + minutesStr + ":" + secondsStr
    }

    const header = (<div className="header-row">
        <span className="user-subs-header user-subs-header-name">Name</span>
        <span className="user-subs-header user-subs-header-time">Time left</span>
        <span className="user-subs-header user-subs-header-actions"></span>
    </div>);

    return (<div id="user-subs">
        <h1 id="user-subs-title">Subs</h1>
        {header}
        {rows}
        <div id="sub-add">
            <button onClick={()=>addSub()}>
                Add
            </button>
        </div>
    </div>)
}