import "./Subs.css";
import { useState, useEffect, useContext } from "react";
import { getSubs, deleteSub, putSub, postSub } from "../../components/TaiderAPI/TaiderAPI";
import { UserContext } from "../../views/App/App.js";
import Add from "../../assets/ic_add.png";
import Sub from "../../components/Sub/Sub";

export default function Subs() {
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");    

    useEffect(() => {
        loadSubs()
    }, []);

    const loadSubs = () => {
        if(taiderUser.userData == null){
            return;
        }
        getSubs(taiderUser.userData.token)
            .then(subs => subs?setRows(mapSubs(subs)):null)
            .catch(handleError);
    };

    const mapSubs = (subs) => {
        var mappedSubs = subs.map(sub=>{return <Sub key={sub.id} sub={sub} new={false}  delete={deleteSub} edit={putSub} token={taiderUser.userData.token} callback={loadSubs}/>});
        return mappedSubs;
    };

    const addSub = () => {
        const timestamp = Date.now();
        setRows(rows.concat([
            <Sub
                key={timestamp}
                sub={{
                    id: timestamp,
                    name:"",
                    price:"",
                    sku:"",
                    duration:"",
                    trial:false,
                    hidden:false,
                }}
                new={true}
                token={taiderUser.userData.token}
                save={postSub}
                delete={removeSub}
                callback={loadSubs}
            />
        ]))
    }

    const removeSub = (id) => {
        setRows(
            rows.filter(sub=>sub.id !== id)
        );
    }

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="subsheader subsheader-name subheader-big">Name</span>
        <span className="subsheader subsheader-price">Price</span>
        <span className="subsheader subsheader-sku subheader-big">SKU</span>
        <span className="subsheader subsheader-duration subheader-small">Duration</span>
        <span className="subsheader subsheader-trial subheader-small">Trial</span>
        <span className="subsheader subsheader-codesenabled subheader-small">Codes enabled</span>
        <span className="subsheader subsheader-visible subheader-small">Visible</span>
        <span className="subsheader subsheader-actions">Actions</span>
    </div>);

    return (<div id="subs">    
        {header}
        {rows}
        <div id="sub-add">
            <button onClick={()=>addSub()}>
                <img src={Add} alt="add new sub"/>
            </button>
        </div>
    </div>);
};