import { useNavigate } from "react-router-dom";
import "./User.css";

export default function User(props) {
    const navigate = useNavigate();
    const row = (<>
        <span className={"userfield userfield-avatar"}>
            <img src={props.user.picture} alt="avatar"/>
        </span>
        <span className={"userfield userfield-name"}>{props.user.name}</span>
        <span className={"userfield userfield-email userfield-big"}>{props.user.email}</span>
        <span className={"userfield userfield-tos userfield-small " + (props.user.tos_accepted?"userfield-positive":"userfield-negative")}>{props.user.tos_accepted?"YES":"NO"}</span>
        <span className={"userfield userfield-admin userfield-small " + (props.user.admin?"userfield-positive":"userfield-negative")}>{props.user.admin?"YES":"NO"}</span>
        <span className={"userfield userfield-actions"}>
            <button onClick={()=>navigate(`/user/${props.user.id}`)}>DETAILS</button>
        </span>
    </>);

    return(
        <div id={props.user.id} className="user">
            {row}
        </div>
    );
}