import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext, useRef } from "react";
import { getPurchases, deletePurchase, getSubs } from "../../components/TaiderAPI/TaiderAPI";
import Purchase from "../../components/Purchase/Purchase.js";
import "./Purchases.css";

export default function Purchases(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const subs = useRef(null);

    useEffect(() => {
        loadPurchases()
    }, []);

    const loadPurchases = async () => {
        if(taiderUser.userData == null){
            return;
        }
        await getSubs(taiderUser.userData.token)
            .then(subs => subs?temp(subs):null)
            .catch(handleError)
        getPurchases(taiderUser.userData.token)
            .then(purchases => purchases?setRows(mapPurchases(purchases)):null)
            .catch(handleError);
    };

    const temp = (subito) => {
        console.log("saving subs");
        subs.current = subito;
    } 

    const mapPurchases = (purchases) => {
        console.log("saving purchases");
        var mappedPurchases = purchases.map(purchase => <Purchase key={purchase.id} token={taiderUser.userData.token} duration={subs.current?subs.current.find(sub=>sub.name === purchase.subName).duration:null} purchase={purchase} delete={deletePurchase} callback={loadPurchases} />)
        return mappedPurchases;
    };
    
    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="purchasesheader purchasesheader-subname">Sub name</span>
        <span className="purchasesheader purchasesheader-username">User name</span>
        <span className="purchasesheader purchasesheader-date">Purchase date</span>
        <span className="purchasesheader purchasesheader-time">Remaining time</span>
        <span className="purchasesheader purchasesheader-actions">Actions</span>
    </div>);

    return (<div id="purchases">
        {header}
        {rows}
    </div>)
}