export const showLoadingModal = () => {
    let modal = document.querySelector("#loading-modal");
    let counter = parseInt(modal.dataset.counter);
    counter = counter + 1;
    modal.dataset.counter = counter;
    modal.style.display = "block";
}

export const hideLoadingModal = () => {
    let modal = document.querySelector("#loading-modal");
    let counter = parseInt(modal.dataset.counter);
    counter = counter - 1;
    modal.dataset.counter = counter;
    if(counter <= 0){
        modal.style.display = "none";
    }
}