import "./Maps.css";
import { getMaps, putMap, verifyMap, uploadMap } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../App/App.js";
import { useState, useEffect, useContext, useRef } from "react";
import Map from "../../components/Map/Map";

export default function Maps(){
    const [taiderUser, ] = useContext(UserContext);
    const [maps, setMaps] = useState(null);
    const [mapResult, setMapResult] = useState(null);
    const mapFile = useRef(null);
    const verifyButton = useRef();
    const uploadButton = useRef();

    useEffect(() => {
        loadMaps()
    }, []);
    
    const loadMaps = () => {
        if(taiderUser.userData == null){
            return;
        }
        getMaps(taiderUser.userData.token)
            .then(maps => maps?setMaps(mapMaps(maps)):null)
            .catch(handleError);
    };

    const mapMaps = (maps) => {
        var mappedMaps = maps.map(map=>{return <Map key={map.id} map={map} token={taiderUser.userData.token} activate={putMap} callback={loadMaps}/>});
        return mappedMaps;
    }

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const handleFile = (target) => {
        console.log("file selection changed");
        if(target.files.length < 1){
            return
        }
        mapFile.current = target.files[0];
    }

    const mapVerifyResult = (verifyResult) => {
        if(verifyResult.valid === true){
            setMapResult(<p className="result-positive">Files are correct</p>);
        }else{
            var invalidLayers = verifyResult.invalidLayers.map(invalidLayer => {return <p className="result-negative">{invalidLayer} is invalid</p>});
            var missingFiles = verifyResult.missingFiles.map(missingFile => {return <p className="result-negative">{missingFile} is missing</p>});
            setMapResult(invalidLayers.concat(missingFiles));
        }
    }

    const mapUploadResult = (uploadResult) => {
        console.log(uploadResult);
        if(uploadResult.active === true){
            setMapResult(<p className="result-positive">Uploaded</p>);
            loadMaps();
        }else{
            setMapResult(<p className="result-negative">Error uploading</p>);
        }
    }
    
    const handleVerify = () => {
        console.log("verify clicked");
        if(mapFile.current == null){
            alert("select file to verify first")
            console.log("null file, aborting verify");
            return
        }
        verifyMap(taiderUser.userData.token, mapFile.current).then(verifyResult => verifyResult?mapVerifyResult(verifyResult):null).catch(handleError);
    }
    
    const handleUpload = () => {
        console.log("upload clicked");
        if(mapFile.current == null){
            alert("select file to upload first")
            console.log("null file, aborting upload");
            return
        }
        console.log("uploading map");
        uploadMap(taiderUser.userData.token, mapFile.current).then(uploadResult => uploadResult?mapUploadResult(uploadResult):null).catch(handleError);
    }

    const newHeader = (<h1>Add new map</h1>);

    const archiveHeader = (<>
        <h1>Previous maps</h1>
        <div className="header-row">
            <span className="mapheader mapheader-name">Upload date</span>
        </div>
    </>);

    const buttons = (<div id="map-buttons">
        <input id="map-file" name="map" type="file" accept=".zip" onChange={(e)=>{handleFile(e.target)}}/>
        <button id="map-verify" ref={verifyButton} onClick={()=>{handleVerify()}} >Verify</button>
        <button id="map-upload" ref={uploadButton} onClick={()=>{handleUpload()}} >Upload</button>
    </div>);

    const cols = <>
        <div id="map-new">
            {newHeader}
            {buttons}
            {mapResult}
        </div>
        <div id="map-separator"></div>
        <div id="map-archive">
            {archiveHeader}
            {maps?maps:<></>}
        </div>
    </>

    return (<div id="maps">{cols}</div>);
}